<template>
  <div class="m-4">
    <TitleComponent
      title="Atualizar dados do Motorista"
      subtitle="Preencha devidamente todos os campos para editar os seus dados e, clique no botão abaixo para salvar."
    />
  </div>

  <overlayPreLoader :isLoading="isLoadingSubmit" />

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">
    <div v-if="isLoadingDetail" class="flex justify-center items-center py-10">
      <InnerPreLoaderComponent />
    </div>

    <div v-else>
    <form @submit.prevent="updateDriver">
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label for="license_number" class="block mb-2 text-sm font-bold text-gray-900">Número da licença</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            id="license_number"
            placeholder="Número da licença"
            type="text"
            v-model="license_number"
          />
        </div>
        <div>
          <label for="license_valid" class="block mb-2 text-sm font-bold text-gray-900">Validade da licença</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            id="license_valid"
            placeholder="Número da licença"
            type="date"
            v-model="license_valid"
          />
        </div>
      </div>
     
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label for="license_date" class="block mb-2 text-sm font-bold text-gray-900">Data de admição</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            id="license_date"
            placeholder="Data de admição"
            type="date"
            v-model="license_date"
          />
        </div>
        <div>
          <label for="license_category" class="block mb-2 text-sm font-bold text-gray-900">Categoria da licença</label>
          <select id="license_category"
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            v-model="license_category" required>
            <option :value="null" disabled selected>Selecione o nível de acesso</option>
            <option v-for="option in license_category_list" :key="option.value" :value="option.value">{{ option.name }}</option>
          </select>
        </div>
      </div>

      <div class="flex justify-end">

        <CustomButton
          label="Atualizar dados"
          :style="'orange'"
          size="large"
          type="submit"
        />
      </div>
    </form>
    </div>
    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">

        <h2 class="text-xl font-bold mb-4 uppercase">Atualizar Dados do perfil?</h2>
        <p class="mb-4">Clique no "sim", para confirmar este evento, e atualizar os dados.</p>

        <div class="grid grid-flow-col justify-stretch">
          <CustomButton :style="'gray'" label="Não" @click="showModal = false" textColor="'text-wewa-orange'" />
          <CustomButton :style="'gray'" label="Sim" @click="showModal2 = true" class="ml-3"
            textColor="'text-wewa-orange'" />
        </div>

      </div>
    </modal>
    <modal :show="showModal2" @close="showModal2 = false">
      <div class="text-left">

        <h2 class="text-xl font-bold mb-4 uppercase">Dados atualizados com sucesso!!</h2>
        <p class="mb-4">A plataforma usará de agora em diante estes novos dados para o seu usuário</p>

        <CustomButton :style="'orange'" label="Entedido" @click="showModal2 = false" />

      </div>
    </modal>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false;" />
      </div>
    </modal>
  </div>
</template>

<script>
import TitleComponent from "../../components/TitleComponent.vue";
import CustomButton from "@/components/CustomButton.vue";
import modal from "@/components/modal/Modal";
import axios from "axios";
import overlayPreLoader from '@/components/preLoader/OverlayPreLoader.vue';
import InnerPreLoaderComponent from "../../components/preLoader/InnerPreLoader.vue"; 

export default {
  components: {
    TitleComponent,
    CustomButton,
    modal,
    overlayPreLoader,
    InnerPreLoaderComponent
  },
  data() {
    return {
      showModal: false,
      showModal2: false,
      license_number: "",
      license_valid: "",
      license_date: "",
      license_category: "",
      license_category_list: [],
      isLoadingDetail: false,
      isLoadingSubmit: false,
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false
    };
  },
  methods: {
    async fetchLicenseCategory() {
      
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Token Não Encontrado";
          this.errorMessage.message = "Token não encontrado no armazenamento local.";
          this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('categories/driving-licenses', {
          headers: customHeaderParams,
        });
        if (response.status === 200 && response.data.success === true) {
          this.license_category_list= response.data.data.map((data) => ({
            value: data.id,
            name: data.name
          }));
        } else {
          this.errorMessage.code = "Erro ao Obter Categorias de Licenças";
          this.errorMessage.message = "Ocorreu um erro ao tentar obter as categorias de licença. Por favor, tente novamente mais tarde.";
          this.showErrorModal = true;
        }
      } catch (error) {
        console.error("Erro ao obter os categorias de licenças:", error);
        this.errorMessage.code = "Erro ao Obter Categorias de Licença";
        this.errorMessage.message = "Ocorreu um erro ao tentar obter os categorias de licença. Por favor, tente novamente mais tarde.";
        this.showErrorModal = true;
      }
    },
    async detailUser() {
      this.isLoadingDetail=true;
      try {
        const driverId = this.$route.params.id;
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Erro de autenticação";
          this.errorMessage.message = "Token não encontrado. Por favor, faça login novamente.";
          this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`drivers/${driverId}`, {
          headers: customHeaderParams,
        });


        if (response.status === 200 && response.data.success === true) {
          const driverData = response.data.data;
          this.license_number = driverData.driving_license_number;
          this.license_valid = driverData.driving_license_validity;
          this.license_date = driverData.driving_license_admission_date;
          this.license_category = driverData.driving_license_category;
        } else {
          this.errorMessage.code = "Erro ao obter usuários";
              this.errorMessage.message = "Ocorreu um erro. Por favor, tente novamente mais tarde.";
              this.showErrorModal = true
        }
      } catch (error) {
        console.error("Erro ao obter os dados do usuário:", error);
        this.errorMessage.code = "Erro ao obter os dados do usuário";
        this.errorMessage.message = "Ocorreu um erro ao tentar obter os dados do usuário. Por favor, tente novamente mais tarde.";
        this.showErrorModal = true;
      }finally{
        this.isLoadingDetail=false;
      }
    },
    async updateDriver() {
      this.isLoadingSubmit=true;
      try {
        const updatedFields = {};

        if (this.license_number) {
          updatedFields.driving_license_number = this.license_number;
        }

        if (this.license_valid) {
          updatedFields.driving_license_validity = this.license_valid;
        }

        if (this.license_date) {
          updatedFields.driving_license_admission_date = this.license_date;
        }

        if (this.license_category) {
          updatedFields.driving_license_category_id = this.license_category;
        }

        if (Object.keys(updatedFields).length === 0) {
          this.errorMessage.code = "Nenhum campo para atualizar.";
            this.errorMessage.message = "Nenhum campo recebeu atualização.";
            this.showErrorModal = true;
          return;
        }

        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Token Não Encontrado";
            this.errorMessage.message = "Token não encontrado no armazenamento local.";
            this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const driverId = this.$route.params.id;

        const response = await axios.post(`drivers/${driverId}`, updatedFields, {
          headers: customHeaderParams,
        });

        // console.log("Editando", response);

        if (response.status === 200 && response.data.success === true) {
          this.showModal2 = true;
        }
      } catch (error) {
        this.isLoadingSubmit = false;

        let validationErrors;

        switch (error.response ? error.response.status : null) {
          case 422:
            validationErrors = error.response.data.error.errors;
            if (validationErrors) {
              const firstErrorKey = Object.keys(validationErrors)[0];
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = validationErrors[firstErrorKey][0];
            } else {
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = "Erro de validação desconhecido.";
            }
            break;
          case 401:
            this.errorMessage.code = "Token Inválido ou Expirado";
            this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
            break;
          case 403:
            this.errorMessage.code = "Acesso Negado";
            this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
            break;
          case 404:
            this.errorMessage.code = "Recurso não encontrado";
            this.errorMessage.message = "O recurso solicitado não foi encontrado.";
            break;
          default:
            if (error.response) {
              this.errorMessage.code = "Erro do Servidor";
              this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
            } else if (error.request) {
              this.errorMessage.code = "Sem Resposta do Servidor";
              this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
            } else {
              this.errorMessage.code = "Erro na Requisição";
              this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
            }
            break;
        }

        this.showErrorModal = true;
      }finally{
        this.isLoadingSubmit=false
      }
    },
  },
  mounted() {
    this.detailUser();
    this.fetchLicenseCategory();
  },
};
</script>

<style></style>
