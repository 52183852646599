<template>
  <div class="m-4">
    <TitleComponent title="Registrar entrada"
      subtitle="Preencha devidamente todos os campos, para registar a nova entrada." />
  </div>

  <overlayPreLoader :isLoading="isLoading" />

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">
    <form @submit.prevent="registerInflow">

      <div class="grid gap-6 mb-6 md:grid-cols-2">

        <div>
          <label for="type" class="block mb-2 text-sm font-bold text-gray-900">Tipo</label>
          <select id="type" v-model="type"
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required="required">
            <option :value="null" disabled selected>-- Selecione o tipo --</option>
            <option v-for="option in genderOptions" :key="option.value" :value="option.value">{{ option.label }}
            </option>
          </select>

        </div>
        <div>
          <label for="first_name" class="block mb-2 text-sm font-bold text-gray-900">Valor</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="1000" required id="first_name" type="number" v-model="value" />
        </div>
      </div>
       <div class="">
            <input type="file" class="custom-file-input" id="customFile" ref="file" @change="handleFileObject()">
                    <label class="custom-file-label text-left" for="customFile">{{ thumbName }}</label>
      </div>
      <div class="">

        <label for="description" class="block mb-2 text-sm font-bold text-gray-900">Descrição</label>
        <textarea id="description" rows="3"
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Fale em detalhes sobre esta entrada a ser registrada..." required
          v-model="description"></textarea>

      </div>

      <div class="flex justify-end">

        <CustomButton label="Registrar entrada" size="medium" :style="'orange'" class="ml-6">
        </CustomButton>
      </div>
    </form>

    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">
        <h2 class="text-xl font-extrabold mb-4 uppercase">Entrada registrada com sucesso!!</h2>
        <p class="mb-4">A sua nova entrada foi registrada. Agora, os dados da sua entrada estão disponíveis para
          manipulação
          no sistema</p>
        <CustomButton label="Entedido" :style="'orange'" size="medium" @click="showModal = false" />
      </div>
    </modal>

    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>

  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import modal from '@/components/modal/Modal';
import CustomButton from '@/components/CustomButton.vue';
import overlayPreLoader from '@/components/preLoader/OverlayPreLoader.vue';
import axios from "axios";

export default {
  components: {
    TitleComponent,
    CustomButton,
    modal,
    overlayPreLoader
  },
  onFileChange(event) {
  const file = event.target.files[0];
  if (file) {
    console.log(file); // Verifique se isso retorna um objeto de arquivo
    this.voucher = file;
  }
},
  data() {
    return {
      genderOptions: [
        { label: 'Comissão', value: 'commission' },
        { label: 'Empréstimo', value: 'loan' },
        { label: 'Aquisição de ação', value: 'share_acquisition' }
      ],
      showModal: false,
      currentDate: this.getCurrentDate(),
      value: "",
      type: "",
      description: "",
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false,
      isLoading: false,
    };
  },
  methods: {
    async registerInflow() {
      try {
        if (
          this.value &&
          this.type &&
          this.description
        ) {
          this.isLoading = true;

          // const registerData = {
          //   value: this.value,
          //   type: this.type,
          //   description: this.description,
          //   document: this.type,
          //   date: this.currentDate,
            
          // };
        const formData = new FormData();
            formData.append('value', this.value);
            formData.append('date', this.currentDate);
            formData.append('description', this.description);
            formData.append('type', this.type);
            formData.append('document', this.thumbnail);

      for (let pair of formData.entries()) {
  console.log(`${pair[0]}: ${pair[1]}`);
}
          const token = localStorage.getItem("token");

          if (!token) {
             this.errorMessage.code = "Token Não Encontrado";
            this.errorMessage.message = "Token não encontrado no armazenamento local.";
            this.showErrorModal = true;
            return;
          }

          const customHeaderParams = {
            Authorization: `Bearer ${token}`,
          };
 console.log(formData);

          const response = await axios.post(`inflows`, formData, {
            headers: customHeaderParams,
          });

          if (response.status === 200 && response.data.success === true) {
            (this.value = ""),
              (this.type = ""),
              (this.description = ""),
              this.showModal = true;
          }
        }
      } catch (error) {
        this.isLoading = false;

        let validationErrors;

        switch (error.response ? error.response.status : null) {
          case 422:
            validationErrors = error.response.data.error.errors;
            if (validationErrors) {
              const firstErrorKey = Object.keys(validationErrors)[0];
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = validationErrors[firstErrorKey][0];
            } else {
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = "Erro de validação desconhecido.";
            }
            break;
          case 401:
            this.errorMessage.code = "Token Inválido ou Expirado";
            this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
            break;
          case 403:
            this.errorMessage.code = "Acesso Negado";
            this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
            break;
          case 404:
            this.errorMessage.code = "Recurso não encontrado";
            this.errorMessage.message = "O recurso solicitado não foi encontrado.";
            break;
          default:
            if (error.response) {
              this.errorMessage.code = "Erro do Servidor";
              this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
            } else if (error.request) {
              this.errorMessage.code = "Sem Resposta do Servidor";
              this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
            } else {
              this.errorMessage.code = "Erro na Requisição";
              this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
            }
            break;
        }

        this.showErrorModal = true;
      } finally {
        this.isLoading = false;
      }
    },
    handleFileObject() {
        this.thumbnail = this.$refs.file.files[0]
        this.thumbName = this.thumbnail.name
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  async mounted() {
    this.registerInflow();
  },
}
</script>

<style></style>