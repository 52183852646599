<template>
  <div class="m-4">
    <div v-if="isLoading" class="flex justify-center items-center py-10">
      <InnerPreLoaderComponent />
    </div>

    <div v-else>
      <ProfileImage :imageUrl="imageUrl" altText="Extra large avatar" />

      <PetitionInfo :name="details.first_name "  editRoute="user_edit" />

      <!-- <div class="flex justify-start my-4">
        <CustomRouterLinkButton :to="{ name: 'user_edit', params: { id: details.id } }" buttonText="Editar Perfil"
          :style="'transparent'" />
      </div> -->


      <PersonalInfo :data="personalData" editRoute="user_edit" />
    </div>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>
  </div>
</template>

<script>
import ProfileImage from "@/components/profile/ProfileImage.vue";
import PersonalInfo from "@/components/profile/CardPetition.vue";
import PetitionInfo from "@/components/profile/PetitionInfo.vue";
// import CustomRouterLinkButton from "@/components/CustomRouterLinkButton.vue";
import InnerPreLoaderComponent from "../../components/preLoader/InnerPreLoader.vue";
import CustomButton from "@/components/CustomButton.vue";
import modal from "@/components/modal/Modal.vue";
import axios from "axios";

export default {
  components: {
    ProfileImage,
    PersonalInfo,
    PetitionInfo,
    // CustomRouterLinkButton,
    InnerPreLoaderComponent,
    modal,
    CustomButton
  },
  data() {
    return {
      imageUrl: require("../../assets/img/avatar/avatar.jpeg"),
      bi_image: require("../../assets/img/avatar/avatar.jpeg"),
      personalData: {
        Email: "",
        // "Nº de passaporte": "",
        Telefone: "",
        "Ponto de Entrega": "",
        "Ponto de Recolha": "",
        "Tipo de Veiculo": "",
        // Gênero: "",
        // Província: "Luanda (Static)",
        // Município: "Cazenga (Static)",
      },
      details: {},
      isLoading: false,
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false,
    };
  },
  methods: {
    async userDetails() {
      this.isLoading = true;
      this.errorMessage.code = "";
      this.errorMessage.message = "";
      this.showErrorModal = false;

      try {
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Erro de autenticação";
          this.errorMessage.message = "Token não encontrado. Por favor, faça login novamente.";
          this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const petitionId = this.$route.params.id;

        const response = await axios.get(`petition/${petitionId}`, {
          headers: customHeaderParams,
        });

        if (response.status === 200 && response.data.success === true) {
          console.log(response.data.data);
          this.details = response.data.data;
       
          this.personalData.Email = this.details.email;
          // this.personalData["Nº de passaporte"] = this.details.profile.passport_number;
          this.personalData.Telefone = this.details.phone_number;
          this.personalData["Ponto de Entrega"]=this.details.load_type;
          this.personalData["Ponto de Recolha"]=this.details.collection_point;
          this.personalData["Tipo de Veiculo"]=this.details.vehicle_type;
        } else {
          this.errorMessage.code = "Erro ao carregar detalhes";
          this.errorMessage.message = "Algo deu errado ao carregar os detalhes do usuário. Tente novamente mais tarde.";
          this.showErrorModal = true;
        }
      } catch (error) {
        console.log("Erro ao exibir detalhe", error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    // getRole(roles) {
    //   return roles && roles.length > 0 ? roles[0].name : '';
    // },
    handleError(error) {
      switch (error.response ? error.response.status : null) {
        case 401:
          this.errorMessage.code = "Token Inválido ou Expirado";
          this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
          break;
        case 403:
          this.errorMessage.code = "Acesso negado";
          this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
          break;
        case 404:
          this.errorMessage.code = "Recurso não encontrado";
          this.errorMessage.message = "O recurso solicitado não foi encontrado.";
          break;
        default:
          if (error.response) {
            this.errorMessage.code = "Erro do Servidor";
            this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
          } else if (error.request) {
            this.errorMessage.code = "Sem Resposta do Servidor";
            this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
          } else {
            this.errorMessage.code = "Erro na Requisição";
            this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
          }
          break;
      }
      this.showErrorModal = true;
    }
  },
  mounted() {
    this.userDetails();
  }
};
</script>

<style></style>
