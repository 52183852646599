import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/tailwind.css'
import router from './router'
import axios from "axios"

const app = createApp(App)
  app.use(router)
  app.mount('#app')

axios.defaults.baseURL = `https://api.dashboard.express.wewa-mobility.com/`
// axios.defaults.baseURL = `http://localhost:8000/`
