<template>
  <div class="m-4">
    <TitleComponent title="Registrar Pedido"
      subtitle="Preencha devidamente todos os campos, para registrar o novo pedido" />
  </div>

  <overlayPreLoader :isLoading="isLoading" />

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">
    <form @submit.prevent="registerPetition">
      <!-- Formulário de cadastro de pedidos -->
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label for="first_name" class="block mb-2 text-sm font-bold text-gray-900">Nome Completo</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o primeiro nome" required id="first_name" type="text" v-model="first_name" />
        </div>
        <div>
          <label for="phone_number" class="block mb-2 text-sm font-bold text-gray-900">Nº de telefone</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Ex.: +244 987654321" required id="phone_number" type="text" v-model="phone_number" />
        </div>
      </div>

      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div class="mb-6">
          <label for="load_type" class="block mb-2 text-sm font-bold text-gray-900">Tipo de Carga</label>
          <select v-model="load_type" @change="filterVehicleOptions"
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
             required>
            <option value="" disabled selected hidden>-- Selecione o tipo de Carga --</option>
            <option value="Documento">Documento</option>
            <option value="Pacote">Pacote</option>
            <option value="Médio Porte">Médio Porte</option>
            <option value="Pesado">Pesado</option>
          </select>
        </div>
        <div class="mb-6">
          <label for="vehicle_type" class="block mb-2 text-sm font-bold text-gray-900">Tipo de Veículo</label>
          <select v-model="vehicle_type"
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
             required>
            <option value="" disabled selected hidden>-- Selecione o tipo de Veículo --</option>
            <option v-for="option in filteredVehicleOptions" :key="option" :value="option">{{ option }}</option>
          </select>
        </div>
      </div>

      <!-- Campos Collection Point e Delivery Point -->
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label for="collection_point" class="block mb-2 text-sm font-bold text-gray-900">Ponto de Coleta</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o ponto de coleta" required id="collection_point" type="text" v-model="collection_point" />
        </div>
        <div>
          <label for="delivery_point" class="block mb-2 text-sm font-bold text-gray-900">Ponto de Entrega</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o ponto de entrega" required id="delivery_point" type="text" v-model="delivery_point" />
        </div>
      </div>

      <div>
        <label for="description" class="block mb-2 font-semibold text-gray-900">Descrição do Pedido<span class="text-red-500">*</span></label>
        <textarea v-model="description" class="bg-gray-100 border-gray-300 text-gray-900 focus:ring-green-600 focus:border-green-600 block w-full px-5 py-3" required></textarea>
      </div>

      <div class="flex justify-end">
        <CustomButton label="Registrar Pedido" :style="'orange'" size="large" type="submit" />
      </div>
    </form>

    <!-- Modais de sucesso e erro -->
    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">Pedido registrado com sucesso!!</h2>
        <p class="mb-4">Seu pedido foi registrado com sucesso e enviado para análise.</p>
        <CustomButton label="Entendido" :style="'orange'" size="medium" @click="showModal = false" />
      </div>
    </modal>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>
  </div>
</template>

<script>
import TitleComponent from "../../components/TitleComponent.vue";
import modal from "@/components/modal/Modal";
import CustomButton from "@/components/CustomButton.vue";
import overlayPreLoader from '@/components/preLoader/OverlayPreLoader.vue';
import axios from "axios";

export default {
  components: {
    TitleComponent,
    CustomButton,
    modal,
    overlayPreLoader,
  },
  data() {
    return {
      showModal: false,
      first_name: "",
      phone_number: "",
      load_type: "",
      vehicle_type: "",
      collection_point: "",
      delivery_point: "",
      description: "",
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false,
      isLoading: false,
      vehicleOptions: [
        "Sinotruck/Caminhão",
        "Dina",
        "Carrinha",
        "Motociclo",
        "Moto-triciclo",
        "Caminhão com cisterna",
      ],
      filteredVehicleOptions: [],
    };
  },
  methods: {
    async registerPetition() {
      this.errorMessage.code = "";
      this.errorMessage.message = "";
      this.showErrorModal = false;
      
      try {
        this.isLoading = true;
        
        // Dados do pedido
        const petitionData = {
          first_name: this.first_name,
          phone_number: this.phone_number,
          load_type: this.load_type,
          vehicle_type: this.vehicle_type,
          collection_point: this.collection_point,
          delivery_point: this.delivery_point,
          description: this.description,
        };

        // Token de autenticação
        const token = localStorage.getItem("token");
        if (!token) {
          this.errorMessage.code = "Token Não Encontrado";
          this.errorMessage.message = "Token não encontrado no armazenamento local.";
          this.showErrorModal = true;
          return;
        }

        // Cabeçalho da requisição com token
        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        // Envio da requisição para o endpoint /petitions
        const response = await axios.post(`/petitions`, petitionData, {
          headers: customHeaderParams,
        });

        // Verificação da resposta
        if (response.status === 200 && response.data.success === true) {
          // Limpa os campos do formulário
          this.first_name = "";
          this.phone_number = "";
          this.load_type = "";
          this.vehicle_type = "";
          this.collection_point = "";
          this.delivery_point = "";
          this.description = "";
          this.showModal = true;
        }
      } catch (error) {
        this.isLoading = false;
        // Tratamento de erros de validação
        if (error.response) {
          this.errorMessage.code = "Erro";
          this.errorMessage.message = error.response.data.message || "Erro ao cadastrar o pedido.";
        } else {
          this.errorMessage.code = "Erro na Requisição";
          this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
        }
        this.showErrorModal = true;
      } finally {
        this.isLoading = false;
      }
    },
    filterVehicleOptions() {
      const optionsByLoadType = {
        Documento: ["Motociclo"],
        Pacote: ["Motociclo", "Moto-triciclo", "Carrinha"],
        "Médio Porte": ["Carrinha", "Dina"],
        Pesado: ["Sinotruck/Caminhão", "Caminhão com cisterna"],
      };
      this.filteredVehicleOptions = optionsByLoadType[this.load_type] || [];
    },
  },
  watch: {
    load_type() {
      this.filterVehicleOptions();
    },
  },
};
</script>

<style scoped>
/* Seu estilo aqui */
</style>
