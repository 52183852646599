<template>
  <div class="w-full  bg-white border border-gray-200 rounded-lg shadow p-5">
    <div class="flex items-center justify-between w-full">
      <span class="text-3xl font-bold text-gray-900 uppercase">{{title}}</span>
      <router-link v-if="showEditIcon" :to="{ name: editRoute }">
        <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path d="M4.5 17.207V19a.5.5 0 00.5.5h1.793a.5.5 0 00.353-.146l8.5-8.5-2.5-2.5-8.5 8.5a.5.5 0 00-.146.353zM15.09 6.41l2.5 2.5 1.203-1.203a1 1 0 000-1.414l-1.086-1.086a1 1 0 00-1.414 0L15.09 6.41z"></path>
        </svg>
      </router-link>
    </div>

    <div class="text-left mt-2.5 mb-5">
      <p class="pb-3" v-for="(value, key) in data" :key="key">
        <span class="font-bold">{{ key }}: </span>{{ value }}
      </p>
    </div>

    <!-- <img v-if="showBI!=false" class="border rounded-xl h-[312px] w-[550px] bg-gray-100" :src="bi_image" alt="BI image"> -->
  </div>
</template>

<script>
export default {
  name: 'PersonalInfo',
  props: {
    data: Object,
    bi_image:String,
    showBI:{
      type: Boolean,
      default: true,
    },
    showEditIcon: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "Dados do Pedido"
    },
    editRoute: String,
  }
};
</script>

<style></style>
